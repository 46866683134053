// extracted by mini-css-extract-plugin
export const cls_LoginPageWrap = "login-module--cls_LoginPageWrap--5WfsO";
export const cls_LoginPage = "login-module--cls_LoginPage--2j8yz";
export const cls_LoginHeaderLogo = "login-module--cls_LoginHeaderLogo--sZMfu";
export const cls_LoginTitle = "login-module--cls_LoginTitle--2xepQ";
export const loginBtnWrap = "login-module--loginBtnWrap--34h9R";
export const loginBtn = "login-module--loginBtn--r40qk";
export const forgotPwdBtn = "login-module--forgotPwdBtn--1N8so";
export const forgotPwdText = "login-module--forgotPwdText--3a-Ti";
export const cls_LoginRemText = "login-module--cls_LoginRemText--qSdDu";
export const notaMemberText = "login-module--notaMemberText--3yQNe";
export const signupLinkText = "login-module--signupLinkText--eA7ik";
export const orText = "login-module--orText--1uFR8";
export const orWrappper = "login-module--orWrappper--38_H0";
export const cls_SocialLoginWrap = "login-module--cls_SocialLoginWrap--35Tv_";
export const socialLogin = "login-module--socialLogin--1TE7t";
export const GitHubLogin = "login-module--GitHubLogin--2kBKe";
export const socialLoginImg = "login-module--socialLoginImg--3rEBK";
export const socialLoginGithubImg = "login-module--socialLoginGithubImg--szjef";
export const fogotPwdTitle = "login-module--fogotPwdTitle--Wms03";
export const forgotPwdWrapper = "login-module--forgotPwdWrapper--3yVPq";
export const forgotPwdDesc = "login-module--forgotPwdDesc--4Oczr";