import { Box, Button, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import Layout from "../components/Layout";
const styles = require("../styles/login.module.css");
import { navigate } from "gatsby";
import { API_URL } from "../constants/constants";
import { toast } from "react-toastify";
import { requestHandler } from "../utils/utils";

const ForgotPassword = () => {
    return(
        <Layout>
            <div className = {styles.forgotPwdWrapper}>
                <div className = {styles.fogotPwdTitle}>
                    Forgot Your Password?
                </div>
                <div className = {styles.forgotPwdBody}>
                    <div className = {styles.forgotPwdDesc}>
                        Enter the registered email address, we will send the procedure to reset password there. 
                    </div>
                    <Formik
                        initialValues={{
                            email: ""
                        }}
                        onSubmit={async (values: any) => {
                            const headers = { "Content-Type": "application/json" , "access_token": localStorage.getItem("access_token")};
                            const result = await requestHandler(API_URL + `developer/forgot-password?email=${values.email}` , null , headers, "POST")
                            if(result.status === "success") 
                            {
                                navigate("/");
                                toast.success(result.message); 
                                
                            }
                            if(result.status === "failed") 
                            {
                                toast.error(result.errorMessage); 
                            }
                        }}>

                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <Box my={3}>
                                    <TextField
                                        error={Boolean(errors.email)}
                                        fullWidth
                                        helperText={errors.email}
                                        label="Email"
                                        margin="normal"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="email"
                                        value={values.email}
                                        variant="standard"
                                    />
                                </Box>
                                <Box my={2} className = {styles.loginBtnWrap}>
                                    <Button
                                        color="primary"
                                        className = {styles.forgotPwdBtn}
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        RESET
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
        </Layout>
    )
}

export default ForgotPassword;